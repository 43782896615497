import React from 'react';
import Gallery from '@tra-sg/gatsby-theme-c360-portal/src/components/Gallery';

const DatalakePage = (props) => (
  <Gallery
    metaTitle="Datasets | c360"
    title="Datasets"
    dashboardName="datalake-page"
    pageComponent={props}
  />
);

export default DatalakePage;
